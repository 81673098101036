import { DEFAULT_NAVIGATOR_LANGUAGE, AVAILABLE_LOCALIZATIONS } from 'constants/localization';

export const getNavigatorLanguage = () => {
    const languages = navigator.languages || [];
    const language = languages[0] || navigator.language || DEFAULT_NAVIGATOR_LANGUAGE;

    return language?.split('-')[0];
};

export const getLocalizationFromAvailable = () => {
    const navigatorLanguage = getNavigatorLanguage();

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(navigatorLanguage);

    return isLanguageLocalized ? navigatorLanguage : null;
};

export const getCurrentLocalization = () => {
    const routerLang = window.location.pathname.split('/')[1];

    const isLanguageLocalized = AVAILABLE_LOCALIZATIONS.includes(routerLang);

    return isLanguageLocalized ? routerLang : null;
};
